import React from "react";
import { View, StyleSheet } from "react-native";

const Line = ({ marginVertical }) => {
  return <View style={[styles.container, { marginVertical }]} />;
};

export default Line;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 1,
    backgroundColor: "#bbb",
  },
});
