import * as React from "react";
import Svg, { Path } from "react-native-svg";

function RegionIcon(props) {
  return (
    <Svg
      width={18}
      height={18}
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M441.374 79.453l-26.485 17.656-44.141-8.828-26.485-8.829-35.313 8.829-26.485-8.829 17.656-35.313h35.313l34.607-17.303a252.853 252.853 0 0171.333 52.617zM238.325 44.14l-8.828 26.484-26.485 8.829-26.485 44.14-44.141 26.485-61.797 8.828v26.485l17.656 17.656v35.313l-26.483-17.657-26.485-17.656-15.361-46.171A255.856 255.856 0 01160.284 18.537l25.072 16.775 52.969 8.828z"
        fill="#03AA6F"
      />
      <Path
        d="M255.982 291.328l-8.828 44.141-35.313 35.313v26.485l-35.313 26.484v44.141l-26.484-8.828-17.657-44.141v-88.282l-44.141-8.828L70.59 282.5v-26.485l17.656-17.656 26.485-26.485 17.656 35.313h61.797l26.485 44.141h35.313zM479.335 130.92c48.193 85.978 42.702 191.996-14.125 272.526l-23.836-23.836v-35.312l-17.656-35.314-17.656-35.313v-35.313l-26.485-17.656-35.313 8.828-61.797-26.485-8.828-61.797 26.485-26.485h52.969l17.656 26.485 52.969 8.828 52.969-17.656 2.648-1.5z"
        fill="#03AA6F"
      />
      <Path
        d="M308.951 88.28l35.313-8.828 26.485 8.828 44.141 8.829 26.485-17.657a252.118 252.118 0 0137.961 51.468l-2.648 1.501-52.969 17.656-52.969-8.828-17.656-26.485h-52.969l-26.485 26.485 8.828 61.797 61.797 26.485 35.313-8.828 26.485 17.656v35.313l17.656 35.313 17.656 35.313v35.313l23.836 23.836c-81.528 115.526-241.275 143.079-356.792 61.55-98.108-69.249-134.913-197.373-88.503-308.122l15.361 46.171 26.485 17.656 26.485 17.656-17.657 17.657V282.5l17.656 35.313 44.141 8.828v88.282l17.656 44.141 26.485 8.828v-44.141l35.313-26.485v-26.485l35.313-35.313 8.828-44.141h-35.313l-26.485-44.141h-61.797l-17.656-35.313-26.485 26.485v-35.313L70.59 185.39v-26.485l61.797-8.828 44.141-26.485 26.485-44.14 26.485-8.828 8.828-26.485-52.969-8.829-25.072-16.774a256.035 256.035 0 01209.758 8.3l-34.607 17.303h-35.313L282.467 79.45l26.484 8.83z"
        fill="#86DAF1"
      />
    </Svg>
  );
}

export default RegionIcon;
