import { useNavigation } from "@react-navigation/native";
import React from "react";
import { Text, View, StyleSheet, Button } from "react-native";

const AboutScreen = () => {
  const navigation = useNavigation();
  return (
    <View>
      <Text>AboutScreen</Text>
      <Button onPress={navigation.navigate("Home")} title="navigate to About" />
    </View>
  );
};

export default AboutScreen;
