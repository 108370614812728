import React from "react";
import "@expo/match-media";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import HomeScreen from "./src/screens/all/HomeScreen";
import AboutScreen from "./src/screens/desktop/AboutScreen";
import SellerPanelAdmin from "./src/screens/all/SellerPanelScreen";
import { Text } from "react-native";
import AccountScreen from "./src/screens/all/AccountScreen";
import LoginScreen from "./src/screens/all/LoginScreen";
import { useFonts } from "expo-font";
import AppLoading from "expo-app-loading";

// const prefix = Linking.createURL("/");
export const API_URL = "https://irplayers-server.vercel.app";
// export const API_URL = "http://localhost:3000";

export default function App() {
  let [fontsLoaded] = useFonts({
    IRANSans: require("./assets/fonts/IRANSans.ttf"),
  });

  const Stack = createStackNavigator();
  const screenOptions = { headerShown: false, title: "IR Players" };
  const linking = {
    // prefixes: [prefix],
    config: {
      Home: "",
      Account: "account/:id",
      // NotFound: "404",
      Login: "login",
    },
  };

  if (!fontsLoaded) {
    return <Text>Loading</Text>;
  } else {
    return (
      <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
        <Stack.Navigator>
          {/* <Stack.Navigator initialRouteName="Home"> */}
          <Stack.Screen
            options={screenOptions}
            name="Home"
            component={HomeScreen}
          />
          <Stack.Screen
            options={screenOptions}
            name="About"
            component={AboutScreen}
          />
          <Stack.Screen
            options={screenOptions}
            name="SellerPanel"
            component={SellerPanelAdmin}
          />
          <Stack.Screen
            options={screenOptions}
            name="Account"
            component={AccountScreen}
          />
          <Stack.Screen
            options={screenOptions}
            name="Login"
            component={LoginScreen}
          />
        </Stack.Navigator>
      </NavigationContainer>
    );
  }
}
