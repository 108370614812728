import React from "react";
import {
  Text,
  View,
  StyleSheet,
  Image,
  FlatList,
  ScrollView,
} from "react-native";
import Line from "./Line";

import { numberWithCommas } from "../utils/validationTools";
import { LinearGradient } from "expo-linear-gradient";

import RegionIcon from "../icons/RegionIcon";

const AccountBox = ({ account }) => {
  const priceWithCommas = numberWithCommas(account.price);
  return (
    <View>
      <LinearGradient
        style={styles.container}
        start={[0, 1]}
        colors={["#303238", "#666"]}
      >
        {account.plus && (
          <View style={styles.plusTextIconView}>
            <Image
              style={styles.plusImage}
              source={require("../../assets/plus.png")}
            />
          </View>
        )}
        <View style={styles.gameNamesView}>
          <View style={styles.titleView}>
            <Text
              style={[
                styles.accountId,
                {
                  backgroundColor: account.isAvailable ? "#77c05d" : "#9b111e",
                },
              ]}
            >
              #{account.id}
            </Text>
            <View style={styles.regionTextIconView}>
              <RegionIcon />
              <Text style={styles.regionText}>{account.region}</Text>
            </View>
          </View>

          <ScrollView style={{ height: 185 }}>
            <Text key={`${account.id}`} style={styles.gameNameText}>
              {account.games?.replace(/ *, */g, "\n")}
            </Text>
          </ScrollView>
        </View>

        <Line marginVertical={5} />

        <Text style={styles.priceText}>{priceWithCommas} Toman</Text>
        <Text style={styles.sellerText}>@{account.sellers.username}</Text>
      </LinearGradient>
    </View>
  );
};

export default AccountBox;

const styles = StyleSheet.create({
  container: {
    width: 300,
    // paddingHorizontal: 10,
    borderRadius: 12,
    // backgroundColor: "#ddd",
    // opacity: 0.95,
    shadowColor: "rgba(0, 0, 0, 0.9)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 20,
    shadowOpacity: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#555",
    marginVertical: 10,
  },
  gameNameText: {
    textAlign: "center",
    fontSize: 13,
    width: "100%",
    color: "white",
  },
  gameNamesView: {
    borderRadius: 7,
    minHeight: 220,
    padding: 10,
    minWidth: 260,
  },
  titleView: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  accountId: {
    textAlign: "center",
    fontWeight: "bold",

    // width: 100,
    paddingVertical: 5,
    paddingHorizontal: 10,
    color: "#fff",
    borderRadius: 10,
  },
  accountStatusText: {
    color: "#777",
    marginLeft: 6,
  },
  title: {
    color: "#555",
  },
  regionText: {
    marginLeft: 7,
    color: "#444",
    color: "white",
  },
  regionTextIconView: {
    justifyContent: "center",
    alignItems: "center",
    width: 35,
    flexDirection: "row",
    position: "absolute",
    right: 0,
  },
  plusTextIconView: {
    justifyContent: "center",
    alignItems: "center",
    width: 35,
    flexDirection: "row",
    position: "absolute",
    right: 10,
    bottom: 10,
  },
  priceText: {
    marginTop: 2,
    marginBottom: 2,
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
    fontSize: 15,
  },
  sellerText: {
    marginTop: 2,
    marginBottom: 10,
    fontWeight: "bold",
    textAlign: "center",
    color: "#aaa",
    // textDecorationLine: "underline",
    fontSize: 12,
  },
  plusImage: {
    width: 25,
    height: 25,
  },
});
