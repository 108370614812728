import axios from "axios";
import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  Platform,
  Image,
  ScrollView,
  TouchableOpacity,
  Linking,
} from "react-native";
import * as Spinner from "react-native-animated-spinkit";
import { API_URL } from "../../../App";
import Header from "../../components/Header";
import Line from "../../components/Line";
import RegionIcon from "../../icons/RegionIcon";
import { numberWithCommas } from "../../utils/validationTools";

const AccountScreen = ({ route }) => {
  const [account, setAccount] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getAccount();
  }, []);
  const getAccount = async () => {
    await axios
      .get(`${API_URL}/account/${route.params.id}`)
      .then((response) => {
        setAccount(response.data.account);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const priceWithCommas = numberWithCommas(account.price);

  const Items = () => {
    return (
      <>
        {account.plus && (
          <View style={styles.plusTextIconView}>
            <Image
              style={styles.plusImage}
              source={require("../../../assets/plus.png")}
            />
          </View>
        )}
        <View style={styles.gameNamesView}>
          <View style={styles.titleView}>
            <Text
              style={[
                styles.accountId,
                {
                  backgroundColor: account.isAvailable ? "#77c05d" : "#9b111e",
                },
              ]}
            >
              #{account.id}
            </Text>
            <View style={styles.regionTextIconView}>
              <RegionIcon />
              <Text style={styles.regionText}>{account.region}</Text>
            </View>
          </View>

          <Text key={`${account.id}`} style={styles.gameNameText}>
            {account.games?.replace(/ *, */g, "\n")}
          </Text>
        </View>
        <Line marginVertical={5} />
        <Text style={styles.priceText}>{priceWithCommas} Toman</Text>
        <Text style={styles.sellerText}>@{account.sellers.username}</Text>
        <Line marginVertical={5} />
        <Text style={styles.noteText}>
          با کلیک برروی دکمه خرید، به تلگرام فروشنده منتقل خواهید شد.
        </Text>
        <Text style={styles.noteText}>
          قبل از کلیک، اکانت را کپی کنید یا از صفحه اسکرین شات بگیرید.
        </Text>
        <Text style={styles.noteText}>
          تمام فروشندگان احرازهویت شده و معتبر می‌باشند
        </Text>
        <Text style={styles.noteText}>
          این وبسایت یک واسطه می‌باشد و درصورت مشکل در خرید، IRPlayers مسئولیتی
          ندارد.
        </Text>
        <Text style={styles.noteText}>
          هنگام خرید بهتر است از فروشنده لینک درگاه بگیرید (به صورت مستقیم به
          کارت واریز نکنید) که قادر به پیگیری باشید.
        </Text>
        <TouchableOpacity
          style={{ marginBottom: 10 }}
          onPress={() => {
            Linking.openURL(`https://t.me/${account.sellers.username}`);
          }}
        >
          <Text style={styles.buyText}>خرید</Text>
        </TouchableOpacity>
      </>
    );
  };
  return (
    <>
      <Image
        style={styles.backgroundImage}
        source={require("../../../assets/minimal.jpg")}
      />
      <ScrollView
        style={{ flex: 1, width: "100%" }}
        contentContainerStyle={{ width: "100%", alignItems: "center" }}
      >
        <View style={styles.container}>
          <Header />
          <LinearGradient
            style={styles.boxContainer}
            start={[0, 1]}
            colors={["#303238", "#666"]}
          >
            {loading ? (
              <View>
                <Spinner.Pulse size={100} color="#aaa" />
              </View>
            ) : (
              <Items />
            )}
          </LinearGradient>
        </View>
      </ScrollView>
    </>
  );
};

{
  /* {route.params ? JSON.stringify(route.params, 5) : "No Params Passed"} */
}
export default AccountScreen;

const styles = StyleSheet.create({
  container: {
    // backgroundColor: "#845",
    // borderWidth: 1,
    width: "100%",
    alignItems: "center",
    height: Platform.OS === "web" ? "100vh" : "100%",
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  boxContainer: {
    width: "90%",
    padding: 10,
    minWidth: 350,
    maxWidth: 600,
    borderRadius: 12,
    shadowRadius: 15,
    shadowOpacity: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#666",
    alignItems: "center",
    // justifyContent: "space-around",
    marginTop: 50,
    minHeight: 300,
  },
  gameNameText: {
    textAlign: "center",
    fontSize: 13,
    width: "100%",
    color: "white",
  },
  gameNamesView: {
    width: "100%",
    // height: "100vh",
    borderRadius: 7,
    minHeight: 220,
    paddingHorizontal: 0,
    paddingVertical: 10,
    minWidth: 260,
  },
  titleView: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  accountId: {
    textAlign: "center",
    fontWeight: "bold",

    // width: 100,
    paddingVertical: 5,
    paddingHorizontal: 10,
    color: "#fff",
    borderRadius: 10,
  },
  accountStatusText: {
    color: "#777",
    marginLeft: 6,
  },
  title: {
    color: "#555",
  },
  regionText: {
    marginLeft: 7,
    color: "#444",
    color: "white",
  },
  regionTextIconView: {
    justifyContent: "center",
    alignItems: "center",
    width: 35,
    flexDirection: "row",
    position: "absolute",
    right: 0,
  },
  plusTextIconView: {
    justifyContent: "center",
    alignItems: "center",
    width: 35,
    flexDirection: "row",
    position: "absolute",
    right: 10,
    bottom: 10,
  },
  priceText: {
    marginTop: 2,
    marginBottom: 2,
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
    fontSize: 15,
  },
  sellerText: {
    marginTop: 2,
    marginBottom: 10,
    fontWeight: "bold",
    textAlign: "center",
    color: "#aaa",
    // textDecorationLine: "underline",
    fontSize: 12,
  },
  plusImage: {
    width: 25,
    height: 25,
  },
  buyText: {
    backgroundColor: "#0a0",
    paddingVertical: 8,
    paddingHorizontal: 40,
    borderRadius: 7,
    color: "#FFF",
    fontFamily: "IRANSans",
  },
  noteText: {
    color: "#FFF",
    width: "100%",
    textAlign: "right",
    marginVertical: 5,
    fontFamily: "IRANSans",
  },
});
