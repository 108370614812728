import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  Platform,
  SafeAreaView,
  Image,
  FlatList,
} from "react-native";
import * as Spinner from "react-native-animated-spinkit";

import AccountBox from "../../components/AccountBox";
import Header from "../../components/Header";

import { API_URL } from "../../../App";
import { useMediaQuery } from "react-responsive";

import { Link } from "@react-navigation/native";
import axios from "axios";

const HomeScreen = () => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchingData();
  }, [page]);

  const fetchingData = () => {
    axios
      .get(`${API_URL}/accounts?page=${page}`)
      .then((response) => {
        setData([...data, ...response.data.accounts]);
        // setTimeout(() => {
        setLoading(false);
        // }, 3 * 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const bigDesktop = useMediaQuery({ minWidth: 1231 });
  const desktop = useMediaQuery({ minWidth: 992, maxWidth: 1230 });
  const tablet = useMediaQuery({ minWidth: 700, maxWidth: 991 });
  const mobile = useMediaQuery({ maxWidth: 700 });

  const numColumns = bigDesktop
    ? 4
    : desktop
    ? 3
    : tablet
    ? 2
    : mobile
    ? 1
    : null;

  return (
    <SafeAreaView
      style={[
        allStyles.container,
        mobile && { height: "95vh" },
        { backgroundColor: "#E7ECF0" },
      ]}
    >
      {loading ? (
        <>
          <Image
            resizeMode="center"
            style={allStyles.image}
            source={require("../../../assets/dualsense.png")}
          />
          <View style={[allStyles.spinnerView, mobile && { bottom: 80 }]}>
            <Spinner.Chase size={100} color="#000" />
          </View>
        </>
      ) : (
        <>
          <Image
            style={allStyles.backgroundImage}
            source={require("../../../assets/minimal.jpg")}
          />

            <Header />

          <View style={allStyles.container2}>
            <FlatList
              style={{ flex: 1, width: "100%" }}
              columnWrapperStyle={
                numColumns > 1
                  ? { flex: 1, justifyContent: "space-around" }
                  : null
              }
              data={data}
              numColumns={numColumns}
              key={
                bigDesktop
                  ? "bd"
                  : desktop
                  ? "d"
                  : tablet
                  ? "t"
                  : mobile
                  ? "m"
                  : null
              }
              contentContainerStyle={mainBoxStyles.container}
              onEndReached={() => {
                setPage(page + 1);
              }}
              onEndReachedThreshold={1}
              ListHeaderComponent={<View style={{ height: 50 }} />}
              renderItem={({ item }) => {
                const linkToObject = `/Account?id=${item.id}`;
                return (
                  <View
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Link key={`${item.id}`} to={linkToObject}>
                      <AccountBox account={item} />
                    </Link>
                  </View>
                );
              }}
            />
            {/* {detailView && <DetailView />} */}
          </View>
        </>
      )}
    </SafeAreaView>
  );
};

export default HomeScreen;

const allStyles = StyleSheet.create({
  container: {
    alignItems: "center",
    height: Platform.OS === "web" ? "100vh" : "100%",
  },
  container2: {
    alignItems: "center",
    height: Platform.OS === "web" ? "100vh" : "100%",
    width: "100%",
  },

  image: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: -1,
  },
  spinnerView: {
    bottom: 50,
    position: "absolute",
  },
  backgroundImage: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
});

const mainBoxStyles = StyleSheet.create({
  container: {
    justifyContent: "center",
    marginHorizontal: 10,
    marginTop: 10,
  },
});
