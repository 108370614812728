import axios from "axios";
import React, { useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  TextInput,
  Image,
  TouchableOpacity,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { API_URL } from "../../../App";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Header from "../../components/Header";

import * as Spinner from "react-native-animated-spinkit";

const LoginScreen = ({ setToken }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);


  const buttonOnPress = async () => {
    if (username.length < 3 || !password) {
      return setError("Enter username & Password");
    }
    setError("");
    setLoading(true);
    await axios
      .get(`${API_URL}/signin?username=${username}&password=${password}`)
      .then(async (response) => {
        setLoading(false);
        if (response.data.status === "401") {
          setError("Wrong username or password");
        } else if (response.data.status === "200") {
          console.log(response.data);

          // AsyncStorage.setItem("userInfo", JSON.stringify(response.data.user));
          // navigation.navigate("SellerPanel");
          AsyncStorage.setItem("token", response.data.token)
            .then(() => {
              setToken(response.data.token);
              location.reload();
            })
            .catch((error) => console.log(error));
        } else {
          setError("network error");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setError("error");
      });
  };

  return (
    <View style={{ alignItems: "center", flex: 1, width: "100%" }}>
      <Header />

      <View style={styles.container}>
        <Image
          style={styles.backgroundImage}
          source={require("../../../assets/minimal.jpg")}
          resizeMode="cover"
        />
        <View style={styles.inputBox}>
          <LinearGradient
            // Background Linear Gradient
            start={[0.7, 0.1]}
            end={[1, 1]}
            // start
            colors={["#666", "#bbb", "#666"]}
            style={styles.linearBackground}
          />
          <TextInput
            placeholder="Username"
            value={username}
            onChangeText={setUsername}
            style={[
              styles.textInput,
              username.length < 3 && username.length !== 0
                ? { borderColor: "#a00", borderWidth: 1 }
                : null,
            ]}
            onSubmitEditing={buttonOnPress}
          />
          <TextInput
            value={password}
            onChangeText={setPassword}
            secureTextEntry={true}
            style={styles.textInput}
            placeholder="Password"
            onSubmitEditing={buttonOnPress}
          />
          <TouchableOpacity disabled={loading} onPress={buttonOnPress}>
            <LinearGradient
              // Background Linear Gradient
              start={[0.7, 0.1]}
              end={[1, 1]}
              // start
              colors={["#666", "#bbb", "#666"]}
              style={styles.button}
            >
              {loading ? (
                <Spinner.Circle color="#000" size="25" />
              ) : (
                <Text style={{ color: "#333", fontWeight: "bold" }}>Login</Text>
              )}
            </LinearGradient>
          </TouchableOpacity>
          {error ? <Text style={styles.errorText}>{error}</Text> : null}
        </View>
      </View>
    </View>
  );
};

export default LoginScreen;

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  linearBackground: {
    width: "100%",
    height: "100%",
    backgroundColor: "#f23",
    position: "absolute",
    zIndex: -1,
    // opacity: 0.9,
    // borderWidth: 1,
    borderRadius: 15,
    opacity: 0.93,
    // borderRadius: 15,
    shadowColor: "rgba(0, 0, 0, 0.8)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 30,
    shadowOpacity: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#555",
  },
  inputBox: {
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
    minWidth: 250,
    minHeight: 300,
    height: "60%",
  },
  textInput: {
    padding: 10,
    marginBottom: 25,
    marginVertical: 4,
    height: 40,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "#ccc",
  },
  backgroundImage: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  button: {
    width: 100,
    height: 35,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    shadowColor: "rgba(0, 0, 0, 0.9)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 2,
  },
  errorText: {
    color: "#a00",
    marginTop: 10,
  },
});
