import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  FlatList,
} from "react-native";

import { useMediaQuery } from "react-responsive";
import { LinearGradient } from "expo-linear-gradient";

import LoginScreen from "./LoginScreen";
import Header from "../../components/Header";

import * as Spinner from "react-native-animated-spinkit";
import { API_URL } from "../../../App";
import axios from "axios";
import { numberWithCommas } from "../../utils/validationTools";
import AddNewAccountBox from "../../components/sellerPanelComponents/AddNewAccountBox";

const SellerPanelAdmin = () => {
  const [token, setToken] = useState("");
  const [selectedTab, setSelectedTab] = useState("Accounts");
  const [selectedAccount, setSelectedAccount] = useState({});
  const [accounts, setAccounts] = useState([]);

  const mobile = useMediaQuery({ maxWidth: 700 });
  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = async () => {
    const token = await AsyncStorage.getItem("token");
    console.log(token, "loge token");
    if (token) {
      setToken(token);
      fetchItems(token);
    }
  };

  const fetchItems = async (token) => {
    const URL = `${API_URL}/selleraccounts`;

    await axios
      .get(URL, {
        headers: {
          authorization: token,
        },
      })
      .then((response) => {
        setAccounts(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const SellerPanel = () => {
    return (
      <View style={sellerPanelStyles.container}>
        <Image
          style={sellerPanelStyles.image}
          source={require("../../../assets/minimal.jpg")}
        />
        <Header logOut />
        <View
          style={[
            panelItemsStyles.container,
            !mobile && {
              flexDirection: "row-reverse",
              width: "90%",
            },
          ]}
        >
          <LeftSideBar />
          <RightBox />
        </View>
      </View>
    );
  };

  const RightBox = () => {
    return (
      <View style={[rightBoxStyles.container, mobile && { width: "100%" }]}>
        {/* <LinearGradient
          // start={[0, 1]}
          colors={["#303238", "#777"]}
          style={rightBoxStyles.backgroundlinear}
        /> */}
        <View
          style={[rightBoxStyles.itemsBoxView, mobile && { marginTop: 40 }]}
        >
          <LinearGradient
            style={leftSidebarStyles.itemsLinear}
            start={[0, 1]}
            colors={["#303238", "#666"]}
          >
            <View style={accountsStyles.container}>
              {selectedTab === "Accounts" && <Accounts />}
              {selectedTab === "Add New Account" && (
                <AddNewAccountBox
                  account={selectedAccount}
                  checkToken={checkToken}
                  setSelectedTab={setSelectedTab}
                />
              )}
            </View>
          </LinearGradient>
        </View>
      </View>
    );
  };
  const AccountsHeader = () => {
    return (
      <View
        style={[
          accountsStyles.headerView,
          !mobile && { justifyContent: "space-around" },
        ]}
      >
        <View style={accountsStyles.accountIdView}>
          <Text style={accountsStyles.headerText}>ID</Text>
        </View>
        <View style={accountsStyles.accountStatusView}>
          <Text style={accountsStyles.headerText}>Stat</Text>
        </View>

        <View style={accountsStyles.accountGamesView}>
          <Text style={accountsStyles.headerText}>Games</Text>
        </View>
        {!mobile && (
          <>
            <View style={accountsStyles.accountRegionView}>
              <Text style={accountsStyles.headerText}>Reg</Text>
            </View>
            <View style={accountsStyles.accountPriceView}>
              <Text style={accountsStyles.headerText}>Price</Text>
            </View>
            <View style={accountsStyles.accountDateView}>
              <Text style={accountsStyles.headerText}>Date</Text>
            </View>
            <View style={accountsStyles.accountPlusView}>
              <Text style={accountsStyles.headerText}>Plus</Text>
            </View>
          </>
        )}
      </View>
    );
  };

  const Line = () => {
    return <View style={styles.line} />;
  };

  const Accounts = () => {
    return (
      <>
        {accounts.length > 1 ? (
          <FlatList
            ListHeaderComponent={AccountsHeader}
            style={{ flex: 1, width: "100%" }}
            data={accounts}
            keyExtractor={(item, index) => item.id}
            renderItem={({ item }) => {
              const priceWithCommas = numberWithCommas(item.price);
              // console.log(item);
              const date = item.createdAt?.split("T");
              // const linkToObject = `/Account?id=${item.id}`;
              return (
                <>
                  <Line />
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedAccount(item);
                      setSelectedTab("Add New Account");
                    }}
                    style={[
                      accountsStyles.flatListView,
                      !mobile && { justifyContent: "space-around" },
                    ]}
                  >
                    <View style={accountsStyles.accountIdView}>
                      <Text style={accountsStyles.itemText}>{item.id}</Text>
                    </View>
                    <View style={accountsStyles.accountStatusView}>
                      <Text style={[accountsStyles.itemText, {fontSize: 20}]}>
                        {item.isAvailable ? "🔵" : "🔴"}
                      </Text>
                    </View>

                    <View style={accountsStyles.accountGamesView}>
                      <Text style={accountsStyles.itemText}>
                        {item.games?.replace(/ *, */g, "\n").slice(0, 250)}
                        {item.games?.length > 249 && "..."}
                      </Text>
                    </View>
                    {!mobile && (
                      <>
                        <View style={accountsStyles.accountRegionView}>
                          <Text style={accountsStyles.itemText}>
                            {item.region}
                          </Text>
                        </View>
                        <View style={accountsStyles.accountPriceView}>
                          <Text style={accountsStyles.itemText}>
                            {priceWithCommas}
                          </Text>
                        </View>
                        <View style={accountsStyles.accountDateView}>
                          <Text style={accountsStyles.itemText}>
                            {date && date[0] + "\n" + date[1]}
                          </Text>
                        </View>
                        <View style={accountsStyles.accountPlusView}>
                          {item.plus ? (
                            <Image
                              style={accountsStyles.plusImage}
                              source={require("../../../assets/plus.png")}
                            />
                          ) : (
                            <Text style={{ color: "#FFF" }}>-</Text>
                          )}
                        </View>
                      </>
                    )}
                  </TouchableOpacity>
                </>
              );
            }}
          />
        ) : (
          <View style={rightBoxStyles.spinner}>
            <Spinner.Circle size={100} color="#DDD" />
          </View>
        )}
      </>
    );
  };

  const leftSidebarItems = [
    { title: "Accounts", linkTo: "selleraccounts" },
    { title: "Add New Account", linkTo: "newaccount" },
    { title: "Profile", linkTo: "profile" },
  ];

  const LeftSideBar = () => {
    return (
      <View
        style={[
          leftSidebarStyles.container,
          mobile && { flexDirection: "row" },
        ]}
      >
        <View
          style={[
            leftSidebarStyles.itemsView,
            mobile
              ? {
                  flexDirection: "row",
                  justifyContent: "center",
                }
              : { height: "80vh" },
          ]}
        >
          {leftSidebarItems.map((item) => {
            return (
              <LinearGradient
                style={[
                  leftSidebarStyles.itemsLinear,
                  selectedTab == item.title && {
                    borderWidth: 1.5,
                    borderColor: "#DDD",
                  },
                ]}
                start={[0, 1]}
                colors={["#63656b", "#999"]}
                key={item.title}
              >
                <TouchableOpacity
                  style={leftSidebarStyles.itemView}
                  onPress={() => {
                    setSelectedTab(item.title);
                    if (item.title === "Add New Account") {
                      setSelectedAccount({});
                    }
                  }}
                >
                  <Text style={leftSidebarStyles.titleText}>{item.title}</Text>
                </TouchableOpacity>
              </LinearGradient>
            );
          })}
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {token ? <SellerPanel /> : <LoginScreen setToken={setToken} />}
    </View>
  );
};

export default SellerPanelAdmin;
const styles = StyleSheet.create({
  container: { flex: 1, justifyContent: "center", alignItems: "center" },
  line: {
    backgroundColor: "#AAA",
    width: "100%",
    height: 1,
    marginVertical: 10,
  },
});

const sellerPanelStyles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: -1,
  },
});

const panelItemsStyles = StyleSheet.create({
  container: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

const rightBoxStyles = StyleSheet.create({
  container: {
    width: "75%",
    // minWidth: 800,
    height: "100vh",
    alignItems: "center",
  },
  backgroundlinear: {
    width: "100%",
    height: "100%",
    position: "absolute",
    opacity: 0.7,
  },
  itemsBoxView: {
    width: "100%",
    marginTop: 100,
  },
  spinner: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

const leftSidebarStyles = StyleSheet.create({
  container: {
    width: "25%",
    // minWidth: 300,
    // height: "100vh",
    alignItems: "center",
    marginLeft: 30,
  },
  backgroundlinear: {
    width: "100%",
    height: "100%",
    position: "absolute",
    opacity: 0.7,
  },
  itemsView: {
    marginTop: 100,
    width: "80%",
  },
  itemView: {
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  itemsLinear: {
    margin: 10,
    borderRadius: 8,
    // shadowColor: "rgba(0, 0, 0, 0.9)",
    shadowRadius: 20,
    borderWidth: 1,
    borderColor: "#777",
  },
  titleText: {
    color: "#FFF",
  },
});

const accountsStyles = StyleSheet.create({
  container: {
    // minHeight: 200,
    height: "80vh",
    width: "100%",
    alignItems: "center",
  },
  headerView: {
    flexDirection: "row",
    width: "100%",
    marginTop: 20,
  },
  headerText: {
    fontWeight: "bold",
    color: "#FFF",
  },
  flatListView: {
    width: "100%",
    flexDirection: "row",
    marginVertical: 10,
  },
  accountIdView: {
    minWidth: 20,
    maxWidth: 60,
    justifyContent: "center",
  },
  accountStatusView: {
    justifyContent: "center",
    alignItems: "center",
    minWidth: 30,
    maxWidth: 100,
  },
  accountGamesView: {
    justifyContent: "center",
    width: 300,
    // maxWidth: 500,
    // backgroundColor: "#F23",
  },
  accountRegionView: {
    minWidth: 30,
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 65,
  },
  accountPriceView: {
    minWidth: 80,
    justifyContent: "center",
    maxWidth: 120,
  },
  accountDateView: {
    minWidth: 100,
    justifyContent: "center",
  },
  accountPlusView: {
    minWidth: 40,
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 50,
  },
  plusImage: {
    width: 25,
    height: 25,
  },
  itemText: {
    color: "#FFF",
  },
});
