import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/core";
import axios from "axios";
import { LinearGradient } from "expo-linear-gradient";
import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Image,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { API_URL } from "../../../App";

import { MaterialCommunityIcons } from "@expo/vector-icons";

const AddNewAccountBox = ({ checkToken, account, setSelectedTab }) => {
  useEffect(() => {
    setAccount();
  }, []);

  const setAccount = () => {
    if (account.id) {
      setGames(account.games.replace(/ *, */g, "\n"));
      setRegion(account.region);
      setPrice(account.price);
      setPlus(account.plus);
      setIsAvailable(account.isAvailable);
    }
  };

  const [region, setRegion] = useState("");
  const [price, setPrice] = useState("");
  const [games, setGames] = useState("");
  const [plus, setPlus] = useState(false);
  const [error, setError] = useState("");
  const [isAvailable, setIsAvailable] = useState(false);

  const navigation = useNavigation();

  const mobile = useMediaQuery({ maxWidth: 700 });

  const submitButtonOnPress = async () => {
    setError("");
    const token = await AsyncStorage.getItem("token");

    if (!token) {
      return navigation.navigate("Login");
    }
    if (!region) {
      return setError("Enter Account Region");
    }
    if (!price) {
      return setError("Enter Account Price");
    }
    if (!games) {
      return setError("Enter Account Games");
    }

    const allGames = games.split("\n");
    if (allGames) {
      const url = account.id ? `editaccount?id=${account.id}&` : "saveaccount?";
      await axios
        .get(
          `${API_URL}/${url}price=${price}&region=${region}&sellerId=1&games=${allGames}&plus=${plus}`,
          {
            headers: {
              authorization: token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          alert(response.data.message);
          if (response.data.status === "200") {
            checkToken();
            setSelectedTab("Accounts");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deactiveOnPress = async () => {
    const token = await AsyncStorage.getItem("token");

    const availability = !isAvailable;

    console.log(availability);

    await axios
      .get(`${API_URL}/deactive/${account.id}?isavailable=${availability}`, {
        headers: {
          authorization: token,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "200") {
          alert("Changed");
          checkToken();
          // setIsAvailable((prev) => !prev);
          setSelectedTab("Accounts");
        } else if (response.data.status === "404") {
          alert("Account Not Found");
          setSelectedTab("Accounts");
          checkToken();
        }
      })
      .catch((error) => {
        console.log(error);
        alert("error");
      });
  };

  return (
    <ScrollView
      style={{
        width: "100%",
      }}
      contentContainerStyle={styles.container}
    >
      <View style={[styles.inputsView, !mobile && { flexDirection: "row" }]}>
        <TextInput
          style={styles.gamesTextInput}
          placeholder="Enter Games"
          value={games}
          onChangeText={setGames}
          multiline
        />
        <View style={styles.rightInputsView}>
          <TextInput
            style={styles.regionTextInput}
            placeholder="Region"
            value={region}
            onChangeText={setRegion}
          />
          <TextInput
            style={styles.priceTextInput}
            placeholder="Price (Toman)"
            value={price}
            onChangeText={setPrice}
          />
          <TouchableOpacity
            onPress={() => {
              setPlus((prev) => !prev);
            }}
          >
            <Image
              style={[styles.plusImage, { opacity: !plus ? 0.4 : 1 }]}
              source={require("../../../assets/plus.png")}
            />
          </TouchableOpacity>
        </View>
      </View>
      {error ? <Text style={styles.errorText}>* {error}</Text> : null}

      <View style={!mobile && { flexDirection: "row" }}>
        <TouchableOpacity onPress={submitButtonOnPress}>
          <LinearGradient
            start={[0, 1]}
            colors={[
              // "#080",
              "#63656b",
              "#999",
            ]}
            // key={item.title}
            style={[
              styles.submitButtonView,
              mobile && { marginBottom: 0, marginTop: 20 },
            ]}
          >
            <Text style={styles.submitButtonText}>
              {account.id ? "Save" : "Submit"}
            </Text>
          </LinearGradient>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setSelectedTab("Accounts");
          }}
        >
          <LinearGradient
            start={[0, 1]}
            colors={["#52545a", "#777"]}
            // key={item.title}
            style={[styles.submitButtonView, mobile && { marginTop: 10 }]}
          >
            <Text style={styles.submitButtonText}>Cancel</Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>

      <TouchableOpacity
        disabled={!account.id}
        style={styles.deleteButtonView}
        onPress={deactiveOnPress}
      >
        <Text style={{ fontSize: 30 }}>{isAvailable ? "🔵" : "🔴"}</Text>
      </TouchableOpacity>
    </ScrollView>
  );
};

export default AddNewAccountBox;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  inputsView: {
    justifyContent: "space-around",
    width: "100%",
    marginTop: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  rightInputsView: {
    justifyContent: "center",
    alignItems: "center",
  },
  regionTextInput: {
    backgroundColor: "#eee",
    paddingVertical: 10,
    paddingHorizontal: 10,
    textAlign: "center",
    width: 150,
    borderRadius: 7,
    fontSize: 18,
    marginTop: 40,
  },
  priceTextInput: {
    backgroundColor: "#eee",
    paddingVertical: 10,
    paddingHorizontal: 10,
    textAlign: "center",
    width: 150,
    borderRadius: 7,
    fontSize: 18,
    marginTop: 40,
    marginHorizontal: 10,
  },
  gamesTextInput: {
    marginTop: 10,
    width: "80%",
    height: 300,
    backgroundColor: "#eee",
    borderRadius: 10,
    padding: 20,
    textAlign: "left",
    marginHorizontal: 10,
  },
  plusImage: {
    width: 40,
    height: 40,
    marginTop: 40,
  },
  submitButtonView: {
    marginTop: 50,
    padding: 20,
    borderRadius: 10,
    marginBottom: 50,
    marginHorizontal: 5,
  },
  deleteButtonView: {
    borderRadius: 10,
    position: "absolute",
    padding: 10,
    top: 0,
    right: 0,
  },
  deleteButtonLinear: {
    width: 40,
    height: 40,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  submitButtonText: {
    color: "#EEE",
    fontSize: 15,
    width: 100,
    textAlign: "center",
  },
  errorText: {
    color: "#FFF",
    marginTop: 20,
  },
});
