// import persianJs from 'persianjs';

export const toEnglishNo = (i) => {
  if (!i) return i;
  return persianJs(i || ' ')
    .toEnglishNumber()
    .toString()
    .trim();
};

// export const NO_LOCAL_STORAGE = typeof window === 'undefined' || !localStorage,
//   LOCAL_STORAGE = !NO_LOCAL_STORAGE;

export const splitDigitsBy3 = (input) => {
  input = input && input.replace(/\s/g, '');
  return input && input.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const toCartForm = (input) => {
  input = input && `${input}`.replace(/\s/g, '');
  return (
    input &&
    input
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim()
  );
};

export const walletName = (i) => {
  if (!i) return;
  const input = i.toString();

  const omni = /^(1|3|bc|Bc|BC|bC)/,
    erc20 = /^(0x|0X)/,
    trc20 = /^(t|T)/;

  if (omni.test(input)) return 'OMNI';
  else if (erc20.test(input)) return 'ERC-20';
  else if (trc20.test(input)) return 'TRC-20';
};

export const removeSpaces = (input) => {
  if (!input) return '';
  return input && `${input}`.replace(/\s/g, '');
};

export const emailValidation = (input) => {
  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return regex.test(input?.trim());
};

export const phoneValidation = (i) => {
  if (!i) return;
  const input = toEnglishNo(i);
  let regex = /(\+98|0)?9\d{9}/;
  return regex.test(input);
};

export const AsteriskEmail = (input) => {
  if (!input) return input;
  const index = input?.indexOf('@'),
    addr = input?.slice(0, 3),
    provider = input?.slice(index, input.length);

  return addr + '***' + provider;
};

export function checkMelliCode(i) {
  if (!i) return;
  let code = toEnglishNo(i);

  var L = code.length;

  if (L < 8 || parseInt(code, 10) == 0) return false;
  code = ('0000' + code).substr(L + 4 - 10);
  if (parseInt(code.substr(3, 6), 10) == 0) return false;
  var c = parseInt(code.substr(9, 1), 10);
  var s = 0;
  for (var i = 0; i < 9; i++) s += parseInt(code.substr(i, 1), 10) * (10 - i);
  s = s % 11;
  return (s < 2 && c == s) || (s >= 2 && c == 11 - s);
}

export const tempFu = () => {};

export const testSheba = (i) => {
  let regex = /^(?:IR)(?=.{24}$)[0-9]*$/;
  return regex.test(i?.trim());
};

export const toMobileNoForm = (phone) => {
  // 09160409061 => 0916 040 9061
  if (!phone || (phone && phone.length < 10)) return phone;
  const toNewForm =
    phone.substring(0, 4) +
    ' ' +
    phone.substring(4, 7) +
    ' ' +
    phone.substring(7, 11);

  return toNewForm;
};

export const isNonEnglish = (i) => {
  if (!i) return i;
  let disallowed = /[^\x00-\x7F]+/;
  return disallowed.test(i);
};

export const deleteNonNumerial = (i) => {
  if (!i) return i;
  var numberPattern = /\d+/g;
  return i.match(numberPattern);
};

export const isNum = (input) => {
  if (!input) return;
  let disallowed = /^\d+$/;
  return disallowed.test(input);
};

export const passValidation = (input) => {
  var regx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  return regx.test(input);
};

export const convertMonthNumToName = (num) => {
  switch (num) {
    case '1':
      return 'ÙØ±ÙˆØ±Ø¯ÛŒÙ†';
    case '2':
      return 'Ø§Ø±Ø¯ÛŒØ¨Ù‡Ø´Øª';
    case '3':
      return 'Ø®Ø±Ø¯Ø§Ø¯';
    case '4':
      return 'ØªÛŒØ±';
    case '5':
      return 'Ù…Ø±Ø¯Ø§Ø¯';
    case '6':
      return 'Ø´Ù‡Ø±ÛŒÙˆØ±';
    case '7':
      return 'Ù…Ù‡Ø±';
    case '8':
      return 'Ø¢Ø¨Ø§Ù†';
    case '9':
      return 'Ø¢Ø°Ø±';
    case '10':
      return 'Ø¯ÛŒ';
    case '11':
      return 'Ø¨Ù‡Ù…Ù†';
    default:
      return 'Ø§Ø³ÙÙ†Ø¯';
  }
};

export const convertDayNumToName = (num) => {
  switch (num) {
    case '1':
      return 'Ø¯ÙˆØ´Ù†Ø¨Ù‡';
    case '2':
      return 'Ø³Ù‡â€ŒØ´Ù†Ø¨Ù‡';
    case '3':
      return 'Ú†Ù‡Ø§Ø±Ø´Ù†Ø¨Ù‡';
    case '4':
      return 'Ù¾Ù†Ø¬Ø´Ù†Ø¨Ù‡';
    case '5':
      return 'Ø¬Ù…Ø¹Ù‡';
    case '6':
      return 'Ø´Ù†Ø¨Ù‡';
    default:
      return 'ÛŒÚ©Ø´Ù†Ø¨Ù‡';
  }
};

export const deleteCommas = (input) => {
  if (!input) {
    return input;
  }
  return input?.toString().replace(/,/g, '');
};

export const numberWithCommas = (x) => {
  if (x < 1 || !x) return x;
  return parseInt(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const deleteGarbagefloats = (i, ac) => {
  if (!i || !ac) return i;

  return i.toString().slice(0, ac + 1);
};

export const toFixFloat = (val, acFl) => {
  if (!val) return val;
  const x = val?.toString();
  const floatPoint = x.indexOf('.');
  const naturalNo = x.slice(0, floatPoint),
    floatNo = x.slice(floatPoint, x.length),
    res = naturalNo + deleteGarbagefloats(floatNo, acFl);

  return parseFloat(res);
};

export function floatNumberWithCommas(val, acFl = 2) {
  if (!val) return val;
  const x = val?.toString();
  const floatPoint = x.indexOf('.');
  if (floatPoint < 0) return numberWithCommas(x);

  const naturalNo = numberWithCommas(x.slice(0, floatPoint)),
    floatNo = x.slice(floatPoint, x.length),
    res = naturalNo + deleteGarbagefloats(floatNo, acFl);

  return res;
}
export const toCardForm = (i) => {
  if (!i) return;

  let joy = i.match(/.{1,4}/g);
  return joy.join(' ');
};

export const toPhoneForm = (input) => {
  if (!input) return;
  const i = removeSpaces(input).replace('-', '');
  const a = i.match(/.{1,3}/g);

  const first = a?.shift(),
    second = a
      ?.join('')
      ?.match(/.{1,4}/g)
      ?.join(' ');
  return !second ? i : first + ' - ' + second;
};

export function checkCartDigit(i) {
  if (!i) return;

  const code = toEnglishNo(i);
  var L = code.length;
  if (
    L < 16 ||
    parseInt(code.substr(1, 10), 10) == 0 ||
    parseInt(code.substr(10, 6), 10) == 0
  )
    return false;
  var c = parseInt(code.substr(15, 1), 10);
  var s = 0;
  var k, d;
  for (var i = 0; i < 16; i++) {
    k = i % 2 == 0 ? 2 : 1;
    d = parseInt(code.substr(i, 1), 10) * k;
    s += d > 9 ? d - 9 : d;
  }
  return s % 10 == 0;
}
export function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return {size: Math.round(bytes / Math.pow(1024, i), 2), sizeType: sizes[i]};
}

export function percentage(num, per) {
  return (num / 100) * per;
}

export function percentageRemained(num, per) {
  return num - percentage(num, per);
}

export const citiesAndCodes = [
  {value: null, label: 'انتخاب'},
  {value: '021', label: '(021)' + ' تهران'},
  {value: '026', label: '(026)' + ' البرز'},
  {value: '025', label: '(025)' + ' قم'},
  {value: '086', label: '(086)' + ' مرکزی'},
  {value: '024', label: '(024)' + ' زنجان'},
  {value: '023', label: '(023)' + ' سمنان'},
  {value: '081', label: '(081)' + ' همدان'},
  {value: '028', label: '(028)' + ' قزوین'},
  {value: '031', label: '(031)' + ' اصفهان'},
  {value: '044', label: '(044)' + ' آذربایجان غربی'},
  {value: '011', label: '(011)' + ' مازندران'},
  {value: '074', label: '(074)' + ' کهگیلویه و بویراحمد'},
  {value: '083', label: '(083)' + ' کرمانشاه'},
  {value: '051', label: '(051)' + ' خراسان رضوی'},
  {value: '045', label: '(045)' + ' اردبیل'},
  {value: '017', label: '(016)' + ' گلستان'},
  {value: '041', label: '(041)' + ' آذربایجان شرقی'},
  {value: '054', label: '(054)' + ' سیستان و بلوچستان'},
  {value: '087', label: '(087)' + ' کردستان'},
  {value: '071', label: '(071)' + ' فارس'},
  {value: '066', label: '(066)' + ' لرستان'},
  {value: '034', label: '(034)' + ' کرمان'},
  {value: '056', label: '(056)' + ' خراسان جنوبی'},
  {value: '013', label: '(013)' + ' گیلان'},
  {value: '077', label: '(077)' + ' بوشهر'},
  {value: '076', label: '(076)' + ' هرمزگان'},
  {value: '061', label: '(061)' + ' خوزستان'},
  {value: '038', label: '(038)' + ' چهار محال و بختیاری'},
  {value: '058', label: '(058)' + ' خراسان شمالی'},
  {value: '035', label: '(035)' + ' یزد'},
  {value: '084', label: '(084)' + ' ایلام'},
];

export const banksAndCodes = [
  {value: 0, label: 'Ù†Ø§Ù… Ø¨Ø§Ù†Ú© Ø±Ø§ Ø§Ù†ØªØ®Ø§Ø¨ Ù†Ù…Ø§ÛŒÛŒØ¯'},
  {value: 1, label: 'Ù…Ù„Øª'},
  {value: 2, label: 'Ø³Ø§Ù…Ø§Ù†'},
  {value: 3, label: 'Ø¢ÛŒÙ†Ø¯Ù‡'},
  {value: 4, label: 'Ø§Ù‚ØªØµØ§Ø¯ Ù†ÙˆÛŒÙ†'},
  {value: 5, label: 'Ø§Ù†ØµØ§Ø±'},
  {value: 6, label: 'Ø§ÛŒØ±Ø§Ù† Ø²Ù…ÛŒÙ†'},
  {value: 7, label: 'Ù¾Ø§Ø±Ø³ÛŒØ§Ù†'},
  {value: 8, label: 'Ù¾Ø§Ø³Ø§Ø±Ú¯Ø§Ø¯'},
  {value: 9, label: 'ØªØ¬Ø§Ø±Øª'},
  {value: 10, label: 'ØªÙˆØ³Ø¹Ù‡ ØªØ¹Ø§ÙˆÙ†'},
  {value: 11, label: 'ØªÙˆØ³Ø¹Ù‡ ØµØ§Ø¯Ø±Ø§Øª Ø§ÛŒØ±Ø§Ù†'},
  {value: 12, label: 'Ø­Ú©Ù…Øª Ø§ÛŒØ±Ø§Ù†ÛŒØ§Ù†'},
  {value: 13, label: 'Ø®Ø§ÙˆØ±Ù…ÛŒØ§Ù†Ù‡'},
  {value: 14, label: 'Ø¯ÛŒ'},
  {value: 15, label: 'Ø±ÙØ§Ù‡ Ú©Ø§Ø±Ú¯Ø±Ø§Ù†'},
  {value: 16, label: 'Ø³Ù¾Ù‡'},
  {value: 17, label: 'Ø³Ø±Ù…Ø§ÛŒÙ‡'},
  {value: 18, label: 'Ø³ÛŒÙ†Ø§'},
  {value: 19, label: 'Ø´Ù‡Ø±'},
  {value: 20, label: 'ØµØ§Ø¯Ø±Ø§Øª Ø§ÛŒØ±Ø§Ù†'},
  {value: 21, label: 'ØµÙ†Ø¹Øª Ùˆ Ù…Ø¹Ø¯Ù†'},
  {value: 22, label: 'Ù‚Ø±Ø¶ Ø§Ù„Ø­Ø³Ù†Ù‡ Ø±Ø³Ø§Ù„Øª'},
  {value: 23, label: 'Ù‚Ø±Ø¶ Ø§Ù„Ø­Ø³Ù†Ù‡ Ù…Ù‡Ø± Ø§ÛŒØ±Ø§Ù†'},
  {value: 24, label: 'Ù‚ÙˆØ§Ù…ÛŒÙ†'},
  {value: 25, label: 'Ú©Ø§Ø±Ø¢ÙØ±ÛŒÙ†'},
  {value: 26, label: 'Ú©Ø´Ø§ÙˆØ±Ø²ÛŒ'},
  {value: 27, label: 'Ú¯Ø±Ø¯Ø´Ú¯Ø±ÛŒ'},
  {value: 28, label: 'Ù…Ø±Ú©Ø²ÛŒ'},
  {value: 29, label: 'Ù…Ø³Ú©Ù†'},
  {value: 30, label: 'Ù…Ù„ÛŒ Ø§ÛŒØ±Ø§Ù†'},
  {value: 31, label: 'Ù¾Ø³Øª Ø¨Ø§Ù†Ú© Ø§ÛŒØ±Ø§Ù†'},
  {value: 32, label: 'Ù…ÙˆØ³Ø³Ù‡ Ø§Ø¹ØªØ¨Ø§Ø±ÛŒ ØªÙˆØ³Ø¹Ù‡'},
  {value: 33, label: 'Ù…ÙˆØ³Ø³Ù‡ Ø§Ø¹ØªØ¨Ø§Ø±ÛŒ Ø¹Ø³Ú©Ø±ÛŒÙ‡(Ù…Ù„Ù„)'},
  {value: 34, label: 'Ù…ÙˆØ³Ø³Ù‡ Ø§Ø¹ØªØ¨Ø§Ø±ÛŒ Ú©ÙˆØ«Ø±'},
  {value: 35, label: 'Ù…ÙˆØ³Ø³Ù‡ Ø§Ø¹ØªØ¨Ø§Ø±ÛŒ Ù†ÙˆØ±'},
  {value: 36, label: 'Ù…ÙˆØ³Ø³Ù‡ Ø§Ø¹ØªØ¨Ø§Ø±ÛŒ Ú©Ø§Ø³Ù¾ÛŒÙ†'},
];

export const cardToBankImage = (cardNo) => {
  if (!cardNo) return;

  const id = cardNo.substring(0, 6);
  const createUrl = (name) => `./images/banks/${name}.png`;

  if (id?.length != 6) return null;

  switch (parseInt(id)) {
    case 603799:
      return createUrl('Bank - Melli - Color'); //melli
    case 589210:
      return createUrl('Bank - Sepah - Color'); //sepah
    case 627648:
      return createUrl('Bank - Saderat - Color'); //saderat
    case 627961:
      return createUrl('Bank - Sanat Madan - Color'); //sanat va madan
    case 603770:
      return createUrl('Bank - Keshavarzi - Color'); //keshavarzi
    case 639217:
      return createUrl('Bank - Keshavarzi - Color'); //keshavarzi
    case 628023:
      return createUrl('Bank - Maskan - Color'); //maskan
    case 627760:
      return createUrl('Bank - Postbank - Color'); //post bank iran
    case 502908:
      return createUrl('Bank - Tosee Taavon - Color'); //tose-e ta-avon
    case 627412:
      return createUrl('Bank - Eghtesad Novin - Color'); //eghtesad novin
    case 622106:
      return createUrl('Bank - Parsian - Color'); //parsian
    case 639194:
      return createUrl('Bank - Parsian - Color'); //parsian
    case 627884:
      return createUrl('Bank - Parsian - Color'); //parsian
    case 502229:
      return createUrl('Bank - Pasargad - Color'); //pasargad
    case 639347:
      return createUrl('Bank - Pasargad - Color'); //pasargad
    case 627488:
      return createUrl('Bank - Karafarin - Color'); //karafarin
    case 502910:
      return createUrl('Bank - Karafarin - Color'); //karafarin
    case 621986:
      return createUrl('Bank - Saman - Color'); //saman
    case 639346:
      return createUrl('Bank - Sina - Color'); //sina
    case 639607:
      return createUrl('Bank - Sarmayeh - Color'); //sarmaye
    case 502806:
      return createUrl('Bank - Shahr - Color'); //shahr
    case 502938:
      return createUrl('Bank - Dey - Color'); //dey
    case 603769:
      return createUrl('Bank - Saderat - Color'); //saderat
    case 610433:
      return createUrl('Bank - Mellat - Color'); //mellat
    case 991975:
      return createUrl('Bank - Mellat - Color'); //mellat
    case 627353:
      return createUrl('Bank - Tejarat - Color'); //tejarat
    case 589463:
      return createUrl('Bank - Refah - Color'); //refah
    case 627381:
      return createUrl('Bank - Ansar - Color'); //ansar
    case 636795:
      return createUrl('Bank - Bank Markazi - Color');
    case 636214:
      return createUrl('Bank - Ayandeh - Color'); //
    case 505416:
      return createUrl('Bank - Gardeshgari - Color'); //
    case 636949:
      return createUrl('Bank - Hekmat - Color'); //
    case 505785:
      return createUrl('Bank - Iran Zamin - Color.png'); //
    case 505801:
      return createUrl('Bank - Kosar - Color'); //
    case 639370:
      return createUrl('Bank - Mehr Eghtesad - Color'); //
    case 606373:
      return createUrl('Bank - Mehr Iran - Color'); //
    case 627648:
      return createUrl('Bank - Tosee Saderat - Color'); //
    case 207177:
      return createUrl('Bank - Tosee Saderat - Color'); //
    case 606256:
      return createUrl('Bank - Melall - Color'); //
    case 504172:
      return createUrl('Bank - Resalat - Color'); //
    case 639599:
      return createUrl('Bank - Ghavamin - Color'); //
    default:
      return createUrl('shetab');
  }
};

export const onEnterPress = (e, event = () => {}) => {
  if (e.key === 'Enter') event();
};

export const bankCodeToName = (code = 0) => banksAndCodes[code].label;
