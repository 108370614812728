import AsyncStorage from "@react-native-async-storage/async-storage";
import { Link } from "@react-navigation/native";
import React from "react";
import {
  Text,
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  Linking,
} from "react-native";
import { useMediaQuery } from "react-responsive";

const Header = ({ logOut, runFunction }) => {
  const bigDesktop = useMediaQuery({ minWidth: 1231 });
  const desktop = useMediaQuery({ minWidth: 992, maxWidth: 1230 });
  const tablet = useMediaQuery({ minWidth: 701, maxWidth: 991 });
  const mobile = useMediaQuery({ maxWidth: 700 });

  return (
    <View style={{ zIndex: 1, top: 0, position: "absolute", width: "90%" }}>
      <View style={styles.container}>
        {mobile ? (
          <Link to="/Home">
            <Image
              style={styles.icon}
              source={require("../../assets/icon.png")}
            />
          </Link>
        ) : (
          <Link to="/Home">
            <Image
              style={styles.iconWithName}
              source={require("../../assets/logo3.png")}
            />
          </Link>
        )}
        <View
          style={[
            leftComponentsStyles.container,
            !mobile && { marginHorizontal: 60 },
          ]}
        >
          <Link style={leftComponentsStyles.text} to="/SellerPanel">
            Enter Seller Panel
          </Link>

          {bigDesktop && (
            <Text style={leftComponentsStyles.text}>Become A Seller!</Text>
          )}
          {desktop || bigDesktop ? (
            <>
              <Text style={leftComponentsStyles.text}>About Us</Text>
              <TouchableOpacity
                onPress={() => {
                  Linking.openURL("https://t.me/apadaa");
                }}
              >
                <Text style={leftComponentsStyles.text}>Call US</Text>
              </TouchableOpacity>
            </>
          ) : null}
        </View>
        <View
          style={[
            rightComponentsStyles.container,
            !mobile && { marginHorizontal: 60 },
          ]}
        >
          {logOut && (
            <Text
              style={leftComponentsStyles.text}
              onPress={() => {
                AsyncStorage.removeItem("token");
                location.reload();
              }}
            >
              Log Out
            </Text>
          )}
        </View>
      </View>
    </View>
  );
  // return (
  //   <View style={{ zIndex: 1 }}>
  //     <View style={styles.container}>
  //       {!mobile && (
  //         <Image
  //           style={styles.image}
  //           source={require("../../assets/logo3.png")}
  //         />
  //       )}
  //       {bigDesktop && <BigDesktopHeader />}
  //       {desktop && <DesktopHeader />}
  //       {tablet && <TabletHeader />}
  //       {mobile && <MobileHeader />}
  //     </View>
  //   </View>
  // );
};

const styles = StyleSheet.create({
  container: {
    // marginTop: 20,
    backgroundColor: "#fff",
    height: 52,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    opacity: 0.95,
    // borderRadius: 15,
    shadowColor: "rgba(0, 0, 0, 0.8)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 30,
    shadowOpacity: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#dce1e6",
  },
  iconWithName: {
    width: 200,
    height: 50,
    marginLeft: 50,
  },
  icon: {
    width: 40,
    height: 40,
    marginLeft: 50,
  },
});

const leftComponentsStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    // marginRight: 200,
  },
  text: {
    fontWeight: "bold",
    marginHorizontal: 20,
    color: "#4d4d4d",
  },
});
const rightComponentsStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    // marginHorizontal: 40,
  },
  text: {
    fontWeight: "bold",
    marginHorizontal: 20,
    color: "#4d4d4d",
  },
});
export default Header;
